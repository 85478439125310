import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './context/authContext';
import { CountryProvider } from './context/CountryContext'; 
import { Provider } from 'react-redux';
import store from "./redux/store";
import ProtectedRoute from "./middleware/ProtectedRoute";
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BiLoaderAlt } from "react-icons/bi";

const Loader = () => {
  return (
    <div className="loader">
      <BiLoaderAlt className="spinner" />
    </div>
  );
};

const Home = lazy(() => import('./views/Home'));
const Login = lazy(() => import('./views/Login'));
const Bag = lazy(() => import('./views/Bag'));
const Category = lazy(() => import('./views/Category'));
const AllCategory = lazy(() => import("./views/AllCategories"));
const Product = lazy(() => import('./views/Product'));
const Checkout = lazy(() => import("./views/Checkout"));
const Profile = lazy(() => import("./views/Profile"));
const Orders = lazy(() => import("./views/Orders"));
const NotFound = lazy(() => import("./views/NotFound"));
const ForgotPassword = lazy(() => import("./views/ForgotPassword"));
const ResetPassword = lazy(() => import("./views/ResetPassword"));
const TermsAndConditions = lazy(() => import("./views/TermCondition"));
const PrivacyPolicy = lazy(() => import("./views/privacyPolicy"));
const CancellationRefundPolicy = lazy(() => import("./views/CancellationPolicy"));
const ContactUs = lazy(() => import("./views/ContactUs"));
const AllProducts = lazy(() => import("./views/AllProducts"));

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <CountryProvider>
          <Router>
            <MainRoutes />
          </Router>
        </CountryProvider>
      </AuthProvider>
    </Provider>
  );
}

const MainRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <ToastContainer />
      <Suspense  fallback={<Loader />}>
        <Routes>
          <Route exact path='/' element={<Home key={location.key} />} />
          <Route exact path='/login' element={<Login key={location.key} />} />
          <Route path="/bag" element={<ProtectedRoute element={<Bag />} />} />
          <Route path='/all-category' element={<AllCategory key={location.key} />} />
          <Route path='/category' element={<Category key={location.key} />} />
          <Route path='/category/:slug' element={<Category key={location.key} />} />
          <Route path='/all-product' element={<AllProducts key={location.key} />} />
          <Route exact path='/product' element={<Product key={location.key} />} />
          <Route exact path='/product/:slug' element={<Product key={location.key} />} />
          <Route path="/checkout" element={<ProtectedRoute element={<Checkout />} />} />
          <Route path="/orders" element={<ProtectedRoute element={<Orders />} />} />
          <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
          <Route exact path='/forgotpassword' element={<ForgotPassword key={location.key} />} />
          <Route exact path='/resetpassword' element={<ResetPassword key={location.key} />} />
          <Route exact path='/terms-condition' element={<TermsAndConditions key={location.key} />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy key={location.key} />} />
          <Route exact path='/cancellation-policy' element={<CancellationRefundPolicy key={location.key} />} />
          <Route exact path='/contact-us' element={<ContactUs key={location.key} />} />
          <Route path="*" element={<NotFound key={location.key} />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
